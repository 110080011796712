import {Container, Divider, Header, Segment} from "semantic-ui-react";
import React, { Component } from 'react'

function Carreer(props){
    return (
        <Segment style={{padding: '8em 0em'}} vertical>
            <Container text>
                <Header as='h3' style={{fontSize: '2em'}}>
                    Работа в AQLIFY
                </Header>
                <p style={{fontSize: '1.33em'}}>
                    Мы ищем инициативных, опытных и сильно мотивированных Full-stack программистов.
                    Отправляйте резюме на jobs@aqlify.com
                </p>
                {/* <Button as='a' size='large'>
          Read More
        </Button> */}

                <Divider
                    as='h4'
                    className='header'
                    horizontal
                    style={{margin: '3em 0em', textTransform: 'uppercase'}}
                >
                    {/* <a href='#'>Case Studies</a> */}
                </Divider>

                {/* <Header as='h3' style={{ fontSize: '2em' }}>
          Did We Tell You About Our Bananas?
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Yes I know you probably disregarded the earlier boasts as non-sequitur filler content, but
          it's really true. It took years of gene splicing and combinatory DNA research, but our
          bananas can really dance.
        </p>
        <Button as='a' size='large'>
          I'm Still Quite Interested
        </Button> */}
            </Container>
        </Segment>

    )
}

export default Carreer
