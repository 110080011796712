/* Heads up!
 * Neither Semantic UI nor Semantic UI React offer a responsive navbar, however, it can be implemented easily.
 * It can be more complicated, but you can create really flexible markup.
 */
import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Icon,
    Image,
    List,
    Menu,
    Responsive,
    Segment,
    Sidebar,
    Visibility,
} from 'semantic-ui-react'
import {Route, withRouter} from "react-router-dom";
import HomepageHeading from "./HomepageHeading";
import Footer from './Footer'
import React, {Component, useState, useEffect, useLayoutEffect} from "react";
import PropTypes from 'prop-types'
import './ResponsiveContainer.css'

// Heads up!
// We using React Static to prerender our docs with server side rendering, this is a quite simple solution.
// For more advanced usage please check Responsive docs under the "Usage" section.
const getWidth = () => {
    const isSSR = typeof window === 'undefined'

    return isSSR ? Responsive.onlyTablet.minWidth : window.innerWidth
}


function DesktopContainer(props) {

    const {history, children, match}=props;

    const [fixed, setFixedMenu] = useState(false);

    const [activeItem, setActiveItem] = useState('');

    console.log(history)

    const handleItemClick = (e, { name }) => {

        console.log(name)
        setActiveItem(name);
        history.push(name)
    }

    const handleContactClick=()=>{
        history.push('/contacts')
    }

    useLayoutEffect(() => {

        setActiveItem(history.location.pathname)

    }, [history.location.pathname]);

    const handleMoreClick = () => {


        history.push('/company')
    }


        let isHomePage=match.isExact;
        //console.log("match",isHomePage)
        let segmentStyle={padding: '1em 0 0 0'}
        if(isHomePage){
            segmentStyle={ minHeight: 700, padding: '1em 0em' }
        }

        return (
            <Responsive getWidth={getWidth} minWidth={Responsive.onlyTablet.minWidth} className="box">
                <div className="row header">
                <Visibility
                    once={false}
                    onBottomPassed={()=>setFixedMenu(true)}
                    onBottomPassedReverse={()=>setFixedMenu(false)}
                >
                    <Segment
                        inverted
                        textAlign='center'
                        style={segmentStyle}
                        vertical
                    >
                        <Menu
                            fixed={fixed ? 'top' : null}
                            inverted={!fixed}
                            pointing={!fixed}
                            secondary={!fixed}
                            size='large'
                        >
                            <Container>
                                <Menu.Item name='/' as='a' active={activeItem === '/'} onClick={handleItemClick}>
                                    Главная
                                </Menu.Item>
                                {/* <Menu.Item as='a'>Work</Menu.Item> */}
                                <Menu.Item name='/company' active={activeItem === '/company'} as='a' onClick={handleItemClick}>Компания</Menu.Item>
                                <Menu.Item name='/carreer' active={activeItem === '/carreer'} as='a' onClick={handleItemClick}>Карьера</Menu.Item>
                                <Menu.Item name='/contacts' active={activeItem === '/contacts'} as='a' onClick={handleItemClick}>Контакты</Menu.Item>
                                {/* <Menu.Item position='right'>
                  <Button as='a' inverted={!fixed}>
                    Log in
                  </Button>
                  <Button as='a' inverted={!fixed} primary={fixed} style={{ marginLeft: '0.5em' }}>
                    Sign Up
                  </Button>
                </Menu.Item> */}

                                <Menu.Item position='right'>

                                    <Button as='a' inverted={!fixed} primary={fixed } onClick={handleContactClick}>
                                        Связаться с нами
                                    </Button>
                                </Menu.Item>
                            </Container>
                        </Menu>
                        <Route exact path="/" render={props => (
                            <HomepageHeading onMoreClicked={handleMoreClick}/>


                        )}/>
                        {/*<HomepageHeading />*/}
                    </Segment>
                </Visibility>
                </div>
                <div className="row content">{children}</div>

                <div className="row footer"><Footer/></div>
            </Responsive>
        )

}

DesktopContainer.propTypes = {
    children: PropTypes.node,
}

DesktopContainer =withRouter(DesktopContainer)

function MobileContainer(props) {
    const {history, children, match} = props;

    const [sidebarOpened, setSideBarOpened] = useState(false);

    const [activeItem, setActiveItem] = useState('');

    console.log(match)

    const handleContactClick=()=>{
        history.push('/contacts')
    }

    const handleItemClick = (e, { name }) => {

        console.log(name)
        setActiveItem(name);
        handleSidebarHide();
        history.push(name)
    }

    const handleMoreClick = () => {


        history.push('/company')
    }

    const handleSidebarHide = () => setSideBarOpened(false)

    const handleToggle = () => setSideBarOpened(true)

    const handlePusherClick = (e) => {

        if (sidebarOpened) {
            handleSidebarHide();
        }

        //this.setState({ sidebarOpened: true })
    }

    let isHomePage=match.isExact;
    //console.log("match",isHomePage)
    let segmentStyle={}
    if(isHomePage){
        segmentStyle={minHeight: 350, padding: '1em 0em'}
    }


    useLayoutEffect(() => {

        setActiveItem(history.location.pathname)

    }, [history.location.pathname]);

    return (
        <Responsive
            as={Sidebar.Pushable}
            getWidth={getWidth}
            maxWidth={Responsive.onlyMobile.maxWidth}
        >

            <Sidebar
                as={Menu}
                animation='push'
                inverted
                onHide={handleSidebarHide}
                vertical
                visible={sidebarOpened}
            >
                <Menu.Item name='/' as='a' active={activeItem === '/'} onClick={handleItemClick}>
                    Главная
                </Menu.Item>
                {/* <Menu.Item as='a'>Work</Menu.Item> */}
                <Menu.Item name='/company' active={activeItem === '/company'} as='a' onClick={handleItemClick}>Компания</Menu.Item>
                <Menu.Item name='/carreer' active={activeItem === '/carreer'} as='a' onClick={handleItemClick}>Карьера</Menu.Item>
                <Menu.Item name='/contacts' active={activeItem === '/contacts'} as='a' onClick={handleItemClick}>Контакты</Menu.Item>
                // {/* <Menu.Item as='a'>Log in</Menu.Item>
//           <Menu.Item as='a'>Sign Up</Menu.Item> */}
            </Sidebar>

            <Sidebar.Pusher dimmed={sidebarOpened} onClick={handlePusherClick} style={{minHeight:'100vh'}}>

                <div className="box">
                <div className="row header">
                <Segment
                    inverted
                    textAlign='center'
                    style={segmentStyle}
                    vertical
                >
                    <Container>
                        <Menu inverted pointing secondary size='large'>
                            <Menu.Item onClick={handleToggle}>
                                <Icon name='sidebar'/>
                            </Menu.Item>
                            <Menu.Item position='right'>
                                <Button as='a' inverted onClick={handleContactClick}>
                                    Связаться с нами
                                </Button>
                                {/*<Button as='a' inverted style={{ marginLeft: '0.5em' }}>*/}
                                {/*Sign Up*/}
                                {/*</Button>*/}
                            </Menu.Item>
                        </Menu>
                    </Container>

                    <Route exact path="/" render={props => (
                        <HomepageHeading mobile onMoreClicked={handleMoreClick}/>


                    )}/>
                </Segment>
                </div>

                <div className="row content">{children}</div>
                <div className="row footer"><Footer/></div>
                </div>
            </Sidebar.Pusher>

        </Responsive>
    )

}

MobileContainer.propTypes = {
    children: PropTypes.node,
}

MobileContainer =withRouter(MobileContainer)

const ResponsiveContainer = ({ children }) => (
    <div>
        <Route path="/" render={props => (
            <DesktopContainer>{children}</DesktopContainer>


        )}/>

        {/*<DesktopContainer>{children}</DesktopContainer>*/}


        <MobileContainer>{children}</MobileContainer>
    </div>
)

ResponsiveContainer.propTypes = {
    children: PropTypes.node,
}

export default ResponsiveContainer