import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Icon,
    Image,
    List,
    Menu,
    Responsive,
    Segment,
    Sidebar,
    Visibility,
} from 'semantic-ui-react'

import {Route, withRouter } from 'react-router-dom';


import president_photo from '../../president_photo.jpeg'
import react_logo from '../../react_logo.jpeg'



function CompanyMain (props) {


    return (
        <div>
            <Segment style={{padding: '5em 0em 5em'}} vertical>
                <Grid container stackable verticalAlign='middle'>
                    <Grid.Row>
                        <Grid.Column width={8}>


                            <Header as='h3' style={{fontSize: '2em'}}>
                                Мы разрабатываем сложные системы международного уровня
                            </Header>
                            <p style={{fontSize: '1.33em'}}>
                               Мы используем самые передовые технологии такие как NodeJs и React/Redux.
                                У нас есть высоко квалифицированные специалисты которые работали во всемирно известных компаниях как
                                CNN

                            </p>



                        </Grid.Column>
                        <Grid.Column floated='right' width={6}>
                            <Image bordered rounded size='large' src={react_logo}/>
                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            </Segment>

            <Segment style={{padding: '5em 0em'}} vertical>
                <Container text>
                            <Header as='h3' style={{fontSize: '2em'}}>
                                Мы сделаем вашу работу эффективнее
                            </Header>
                            <p style={{fontSize: '1.33em'}}>
                                {/* <Image avatar src='/images/avatar/large/nan.jpg' /> */}
                                {/* <b>Nan</b> Chief Fun Officer Acme Toys */}
                                Мы помогаем извлечь максимум возможного для клиента из совокупности всех современных технологий (как
                                опробованных, так и новейших, быстро развивающихся),
                                чтобы внедрять инновации, создавать эффективные решения и увеличивать продуктивность.
                            </p>
                </Container>
            </Segment>

            <Segment style={{padding: '5em 0em'}} vertical>
                <Container text>
                    <Header as='h3' style={{fontSize: '2em'}}>
                       Мы сделаем так чтобы на вас работали лучшие инновации
                    </Header>
                    <p style={{fontSize: '1.33em'}}>
                        Мы помогаем клиентам эффективно работать с инновациями, претворяя их в жизнь. То есть делаем так, чтобы они работали в бизнесе клиента и приносили результат. В промышленных масштабах.



                        Мы приносим лучшие мировые инновации в Узбекистан, работая с ними в наших перспективных проектах и предлагая узбекистанским клиентам инновационные технологии и решения.
                    </p>
                    {/* <Button as='a' size='large'>
          Read More
        </Button> */}

                    <Divider
                        as='h4'
                        className='header'
                        horizontal
                        style={{margin: '3em 0em', textTransform: 'uppercase'}}
                    >
                        {/* <a href='#'>Case Studies</a> */}
                    </Divider>

                    {/* <Header as='h3' style={{ fontSize: '2em' }}>
          Did We Tell You About Our Bananas?
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Yes I know you probably disregarded the earlier boasts as non-sequitur filler content, but
          it's really true. It took years of gene splicing and combinatory DNA research, but our
          bananas can really dance.
        </p>
        <Button as='a' size='large'>
          I'm Still Quite Interested
        </Button> */}
                </Container>
            </Segment>


        </div>
    )
}

export default CompanyMain