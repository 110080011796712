/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
import {Button, Container, Header, Icon} from "semantic-ui-react";
import PropTypes from 'prop-types'
import React from "react";

const HomepageHeading = ({ mobile, onMoreClicked }) => (
    <Container text>
        <Header
            as='h1'
            content='AQLIFY'
            inverted
            style={{
                fontSize: mobile ? '2em' : '4em',
                fontWeight: 'normal',
                marginBottom: 0,
                marginTop: mobile ? '1.5em' : '3em',
                fontFamily: "GoodTimes"
            }}
        />
        <Header
            as='h2'
            content='Разработка информационных систем'
            inverted
            style={{
                fontSize: mobile ? '1.5em' : '1.7em',
                fontWeight: 'normal',
                marginTop: mobile ? '0.5em' : '1.5em',
            }}
        />
        <Button primary size='huge' onClick={onMoreClicked}>
            Подробнее
            <Icon name='right arrow' />
        </Button>
    </Container>
)

HomepageHeading.propTypes = {
    mobile: PropTypes.bool,
}

export default HomepageHeading