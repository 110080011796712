import React from 'react'
import {Button, Checkbox, Form, Container, Segment, TextArea, List, Card, Header} from 'semantic-ui-react'

function ContactsMain (props) {

   // style={{minHeight:'100vh'}}

    return (

        <Segment style={{padding: '5em 0em' }} vertical>
            <Container text>
                {/*<Form>*/}
                    {/*<Form.Field>*/}
                        {/*<label>Ваше имя</label>*/}
                        {/*<input placeholder='Ваше имя'/>*/}
                    {/*</Form.Field>*/}
                    {/*<Form.Field>*/}
                        {/*<label>Ваш email</label>*/}
                        {/*<input placeholder='Ваш email'/>*/}
                    {/*</Form.Field>*/}
                    {/*<Form.Field*/}
                        {/*id='form-textarea-control-opinion'*/}
                        {/*control={TextArea}*/}
                        {/*label='Сообщение'*/}
                        {/*placeholder='Ваше сообщение'*/}
                    {/*/>*/}
                    {/*<Button type='submit'>Отправить</Button>*/}
                {/*</Form>*/}

                <Header as='h3' style={{fontSize: '2em'}}>
                    Наши контакты
                </Header>
                <p style={{fontSize: '1.33em'}}>
                    <List>
                        <List.Item>
                            <List.Icon name='users' />
                            <List.Content>AQLIFY</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Icon name='marker' />
                            <List.Content>Tashkent</List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Icon name='mail' />
                            <List.Content>
                                <a href='mailto:sales@aqlify.com'>sales@aqlify.com</a>
                            </List.Content>
                        </List.Item>
                        <List.Item>
                            <List.Icon name='phone' />
                            <List.Content>
                                +998974901777
                            </List.Content>
                        </List.Item>

                    </List>
                </p>




            </Container>
        </Segment>
    )
}

export default ContactsMain
