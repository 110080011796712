import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  List,
  Menu,
  Responsive,
  Segment,
  Sidebar,
  Visibility,
} from 'semantic-ui-react'

import {Route, withRouter } from 'react-router-dom';
import ResponsiveContainer from './ResponsiveContainer'

import HomeMain from '../scenes/Home'
import CompanyMain from '../scenes/Company'
import Carreer from '../scenes/Carreer'

import ContactsMain from '../scenes/Contacts'


import president_photo from '../president_photo.jpeg'
import HomepageHeading from "./HomepageHeading";



function HomepageLayout (props) {


  return (
      <ResponsiveContainer>
        <Route exact path="/" component={HomeMain} />
        <Route exact path="/company" component={CompanyMain} />
        <Route exact path="/contacts" component={ContactsMain} />
        <Route exact path="/carreer" component={Carreer} />




      </ResponsiveContainer>
  )
}

export default HomepageLayout