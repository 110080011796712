/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
import {
    Button,
    Container,
    Divider,
    Grid,
    Header,
    Icon,
    Image,
    List,
    Menu,
    Responsive,
    Segment,
    Sidebar,
    Visibility,
} from 'semantic-ui-react'
import PropTypes from 'prop-types'
import React from "react";

import ResponsiveContainer from "./HomepageLayout";
import {Route, withRouter } from 'react-router-dom';

const Footer = (props) => {

    const handleContactClick=()=>{
        props.history.push("/contacts")
    }


    return (
        <Segment inverted vertical style={{padding: '5em 0em'}}>
            <Container>
                <Grid divided inverted stackable>
                    <Grid.Row>
                        <Grid.Column width={3}>
                            <Header inverted as='h4' content='О нас'/>
                            <List link inverted>
                                {/* <List.Item as='a'>Sitemap</List.Item> */}
                                <List.Item as='a' onClick={handleContactClick}>Свяжиьесь с нами</List.Item>
                                {/* <List.Item as='a'>Religious Ceremonies</List.Item>
                <List.Item as='a'>Gazebo Plans</List.Item> */}
                            </List>
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Header inverted as='h4' content='Услуги'/>
                            <List link inverted>
                                <List.Item as='a'>Разработка систем автоматизации</List.Item>
                                <List.Item as='a'>Разработка мобильных приложений</List.Item>
                                <List.Item as='a'>Создание сложных IT проектов</List.Item>
                                {/* <List.Item as='a'>Favorite X-Men</List.Item> */}
                            </List>
                        </Grid.Column>
                        <Grid.Column width={7}>
                            <Header as='h4' inverted>
                                Контакты
                            </Header>
                            <p>
                                Отдел продаж:
                                +99897-490-1777
                            </p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </Segment>
    )
}


export default withRouter(Footer)